<!--
 * @Description: 会议列表
 * @Author: wangqiannan
 * @Date: 2022-08-05 09:00:00
-->

<template>
  <div class="ind-crouse mt15 w_auto">
    <div>
      <div class="ind-title flex-between">
        <div class="ind-name">
          <i class="el-icon-caret-right"></i>
          <span class="font18 midt">{{item.floorName}}</span>
        </div>
        <div class="more">
          <span @click="meetClick">全部</span>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div><Meeting :item="item"></Meeting></div>
    </div>
  </div>
</template>

<script>
import Meeting from "./meeting/index";
export default {
  props:{
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  components: { Meeting },
  data() {
    return {
    };
  },
  methods:{
    meetClick(){
       this.$router.push({
        path:'/meet/index'
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.ind-crouse[data-v-ce40753e] {
  width: 100%;
  display: inline-block;
}
.mt15 {
  margin-top: 15px;
}
.ind-title {
  padding: 10px 0;
  margin-bottom: 15px;
  align-items: center;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.ind-name {
  display: flex;
  align-items: center;
}
.ind-name .midt {
  position: relative;
  margin-left: 5px;
  font-size: 18px;
}

.more {
  font-size: 14px;
}
.ind-name ::v-deep .el-icon-caret-right {
  font-size: 25px;
}
</style>